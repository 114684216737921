"use strict";
function initMainHeader() {
    const headerTrigger = document.querySelector('.site-header-trigger');
    const navHeader = document.querySelector('.nav-primary');
    const body = document.querySelector('body');
    navHeader.querySelectorAll('a').forEach(element => element.setAttribute('tabindex', '-1'));
    headerTrigger.addEventListener('click', () => {
        if (navHeader.classList.contains('hide')) {
            navHeader.classList.remove('hide');
            document.querySelector('body')?.classList.add('mobile-menu');
            navHeader.querySelectorAll('a').forEach(element => element.setAttribute('tabindex', '0'));
            if (body) {
                body.style.overflow = 'hidden';
            }
            headerTrigger.classList.remove('closed');
            headerTrigger.classList.add('open');
            headerTrigger.setAttribute('aria-label', 'Close the main menu');
        }
        else {
            navHeader.classList.add('hide');
            document.querySelector('body')?.classList.remove('mobile-menu');
            navHeader.querySelectorAll('a').forEach(element => element.setAttribute('tabindex', '-1'));
            if (body) {
                body.style.overflow = 'initial';
            }
            headerTrigger.classList.remove('open');
            headerTrigger.classList.add('closed');
            headerTrigger.setAttribute('aria-label', 'Open the main menu');
        }
    });
    const siteHeader = document.querySelector('.site-header');
    const nav_primary = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1020 ? siteHeader.querySelector('.nav-primary') : siteHeader.querySelector("header");
    let lastScrollTop = 0;
    document.addEventListener('scroll', () => {
        const stickyElements = document.querySelectorAll('.elementor-sticky');
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop === 0) {
            siteHeader.classList.remove('scrolled');
            nav_primary.style.top = "0px";
            stickyElements.forEach(element => {
                element.style.top = "0px";
                element.style.position = "sticky";
            });
            return;
        }
        if (scrollTop > (nav_primary.offsetHeight + lastScrollTop)) {
            // reset lastScrollTop when scrolling down
            lastScrollTop = scrollTop;
            siteHeader.style.top = "-100px";
            siteHeader.classList.add('scrolled');
            stickyElements.forEach(element => {
                element.style.top = nav_primary.offsetHeight + "px";
                element.style.position = "sticky";
            });
        }
        // check if we are scrolling up
        if ((scrollTop + nav_primary.offsetHeight) < lastScrollTop) {
            lastScrollTop = scrollTop;
        }
    });
}
// on width changed
const updateDropDownOffset = () => {
    const form_select_menus = document.querySelectorAll('.form-select-menu');
    // document width
    const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const form_select_width = form_select_menus[0]?.offsetWidth || 0;
    //     transform: translate3d(-131px, 60px, 0px);
    form_select_menus.forEach(element => {
        const offset = element.offsetLeft;
        const new_offset = -(width - form_select_width) / 2 - offset;
        element.style.transform = `translate3d(${new_offset}px, 60px, 0px)`;
    });
};
const hidePreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
        setTimeout(() => {
            preloader.classList.add('off');
        }, 1000);
    }
};
window.addEventListener('resize', () => {
    updateDropDownOffset();
});
document.addEventListener('DOMContentLoaded', () => {
    initMainHeader();
    updateDropDownOffset();
    hidePreloader();
});
window.addEventListener("load", () => {
    const sticky = document.querySelector('.elementor-sticky');
    if (sticky)
        sticky.style.top = "unset";
});
